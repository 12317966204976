<template>
  <Header index="0"/>
  <div class="main">
    <div class="carousel" :style="{marginTop: '3vw'}">
      <a-carousel :style="{ width: '100%', height: '33vw', }" :default-current="1" animation-name="card">
        <a-carousel-item v-for="image in carouselImgs" :style="{width: '80%',}">
          <a :href="'/product?nftId=' + image.artworkId "><img :src="image.slideshowImg" :style="{width: '100%',}"/></a>
        </a-carousel-item>
      </a-carousel>
    </div>
    <a-row>
      <a-col :span="2"></a-col>
      <a-col :span="20">
        <div class="_singe">
          <div :style="{marginTop: '2vw', marginBottom: '2vw'}">
            <a-row>
              <a-col :span="8">
                <span :style="{color: '#fff' ,fontWeight: 'bold' ,fontSize: 'xx-large'}">单品推荐</span>
              </a-col>
              <a-col :span="14"></a-col>
              <a-col :span="2">
                <a href="/market"><span :style="{color: '#fff' ,fontSize: '1.4vw'}">查看更多></span></a>
              </a-col>
            </a-row>
          </div>
          <a-row>
            <a-col span="2"></a-col>
            <a-col span="20">
              <div class="_item" v-for="(singleRecommend ,index) in singleRecommends">
                <a :href="'/product?nftId=' + singleRecommend.nftId">
                  <div v-if="index % 2 == 0">
                    <a-row>
                      <a-col :span="8">
                        <div class="_artworkUrl">
                          <img :src="singleRecommend.artworkUrl"/>
                        </div>
                      </a-col>
                      <a-col :span="8"></a-col>
                      <a-col :span="8">
                        <div class="_text">
                          <div class="_name">{{ singleRecommend.nftName }}</div>
                          <div class="_price">￥{{ singleRecommend.price }}</div>
                          <div class="_nftTotal">
                            <span>限量</span><span>{{ singleRecommend.nftTotal }}份</span>
                          </div>
                          <div class="_user">
                            <a-row>
                              <a-col :span="4"><img :src="singleRecommend.artistAvatar"/></a-col>
                              <a-col :span="20">
                                <div class="_nickname">{{ singleRecommend.artistName }}</div>
                              </a-col>
                            </a-row>
                          </div>
                          <BuyBtn :info="singleRecommend" float="left"/>
                        </div>
                      </a-col>
                    </a-row>
                  </div>
                </a>
                <a :href="'/product?nftId=' + singleRecommend.nftId ">
                  <div v-if="index % 2 == 1">
                    <a-row>
                      <a-col :span="8">
                        <div class="_text">
                          <div class="_name">{{ singleRecommend.nftName }}</div>
                          <div class="_price">￥{{ singleRecommend.price }}</div>
                          <div class="_nftTotal">
                            <span>限量</span><span>{{ singleRecommend.nftTotal }}份</span>
                          </div>
                          <div class="_user">
                            <a-row>
                              <a-col :span="4"><img :src="singleRecommend.artistAvatar"/></a-col>
                              <a-col :span="18">
                                <div class="_nickname">{{ singleRecommend.artistName }}</div>
                              </a-col>
                            </a-row>
                          </div>
                          <BuyBtn :info="singleRecommend" float="left"/>
                        </div>
                      </a-col>
                      <a-col :span="8"></a-col>
                      <a-col :span="8">
                        <div class="_artworkUrl">
                          <img :src="singleRecommend.artworkUrl"/>
                        </div>
                      </a-col>
                    </a-row>
                  </div>
                </a>
              </div>
            </a-col>
            <a-col span="2"></a-col>
          </a-row>
        </div>
        <!--   推荐列表     -->
        <div class="_list">
          <List :list="recommendList" cols="4" @clickItem="clickRecommend"/>
        </div>
        <!--    艺术家列表    -->
        <div class="artists">
          <div :style="{marginTop: '20px', marginBottom: '20px'}">
            <a-row>
              <a-col :span="8">
                <span :style="{color: '#fff' ,fontWeight: 'bold' ,fontSize: 'xx-large'}">艺术家</span>
              </a-col>
              <a-col :span="14"></a-col>
              <a-col :span="2">
                <a href="/"><span :style="{color: '#fff' ,fontSize: '16px'}">查看更多></span></a>
              </a-col>
            </a-row>
          </div>
          <Carousel v-model:active="active" view-size="6" style="max-width: 100%;">
            <CarouselItem v-for="artist in artists" :key="artist">
              <div class="_artist">
                <div class="_avatar">
                  <img :src="artist.avatar">
                </div>
                <div class="_nickname">{{ artist.nickname }}</div>
                <div class="_follow" v-if="!artist.isFollow" @click="doFollow">+关注</div>
                <div class="_followed" v-if="artist.isFollow">已关注</div>
                <div class="_fans">
                  <a-row>
                    <a-col :span="1">
                      <img src="./assets/artist_fans.svg" width="26">
                    </a-col>
                    <a-col :span="11">粉丝{{ artist.fans }}</a-col>
                    <a-col :span="1">
                      <img src="./assets/artist_work.svg">
                    </a-col>
                    <a-col :span="11">作品{{ artist.nfts }}</a-col>
                  </a-row>
                </div>
              </div>
            </CarouselItem>
          </Carousel>
        </div>
      </a-col>
      <a-col :span="2"></a-col>
    </a-row>
  </div>
  <LoginModal :active="loginModal"></LoginModal>
  <Footer/>
</template>

<script>
import Header from '/src/components/header/header.vue'
import Footer from '/src/components/footer/footer.vue'
import * as math from 'mathjs'
// import List from '/src/components/list/list'
import LoginModal from '/src/components/loginModal/loginModal'
import global from "@/global";
import request from "/src/components/utils/axios.js";
import {defineAsyncComponent} from 'vue'

const List = defineAsyncComponent(() =>
    import('/src/components/list/list')
)

import BuyBtn from "/src/components/BuyBtn"

export default {
  name: 'App',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Header
    , Footer
    , List
    , BuyBtn
    , LoginModal
  }
  , data() {
    let artists = [
      {
        'uid': 2,
        'nickname': '兔兔这么可爱',
        'avatar': require("/src/assets/avatar/2.png"),
        'fans': 10,
        'nfts': 20,
        'isFollow': false
      }
      , {
        'uid': 2,
        'nickname': '兔兔这么可爱',
        'avatar': require("/src/assets/avatar/1.gif"),
        'fans': 10,
        'nfts': 20,
        'isFollow': false
      }
      , {
        'uid': 2,
        'nickname': '兔兔这么可爱',
        'avatar': require("/src/assets/avatar/2.png"),
        'fans': 10,
        'nfts': 20,
        'isFollow': true
      }
      , {
        'uid': 2,
        'nickname': '兔兔这么可爱',
        'avatar': require("/src/assets/avatar/1.gif"),
        'fans': 10,
        'nfts': 20,
        'isFollow': false
      }
      , {
        'uid': 2,
        'nickname': '兔兔这么可爱',
        'avatar': require("/src/assets/avatar/2.png"),
        'fans': 1000,
        'nfts': 2000,
        'isFollow': false
      }
      , {
        'uid': 2,
        'nickname': '兔兔这么可爱',
        'avatar': require("/src/assets/avatar/1.gif"),
        'fans': 10,
        'nfts': 20,
        'isFollow': false
      }
      , {
        'uid': 2,
        'nickname': '兔兔这么可爱',
        'avatar': require("/src/assets/avatar/2.png"),
        'fans': 10,
        'nfts': 20,
        'isFollow': false
      }
      , {
        'uid': 2,
        'nickname': '兔兔这么可爱',
        'avatar': require("/src/assets/avatar/1.gif"),
        'fans': 10,
        'nfts': 20,
        'isFollow': false
      }
      , {
        'uid': 2,
        'nickname': '兔兔这么可爱',
        'avatar': require("/src/assets/avatar/2.png"),
        'fans': 10,
        'nfts': 20,
        'isFollow': false
      }
      , {
        'uid': 2,
        'nickname': '兔兔这么可爱',
        'avatar': require("/src/assets/avatar/1.gif"),
        'fans': 10,
        'nfts': 20,
        'isFollow': false
      }
      , {
        'uid': 2,
        'nickname': '兔兔这么可爱',
        'avatar': require("/src/assets/avatar/2.png"),
        'fans': 10,
        'nfts': 20,
        'isFollow': false
      }
      , {
        'uid': 2,
        'nickname': '兔兔这么可爱',
        'avatar': require("/src/assets/avatar/1.gif"),
        'fans': 10,
        'nfts': 20,
        'isFollow': false
      }
    ];
    return {
      isLogin: global.isLogin()
      , carouselImgs: []
      , singleRecommends: []
      , recommendList: []
      , artists: artists
      , loginModal: false
    }
  }
  , mounted() {
    $('.main').css('min-height', (window.screen.height - 120) + "px");
    this.getCarousels(carousels => this.carouselImgs = carousels);
    let that = this;
    this.getRecommends(recommends => {
      that.singleRecommends = recommends.slice(0, 2);
      that.recommendList = recommends.slice(2, recommends.length);
      for (let i = 0; i < that.singleRecommends.length; i++) {
        that.infoProcess(that.singleRecommends[i]);
      }
      for (let i = 0; i < that.recommendList.length; i++) {
        that.infoProcess(that.recommendList[i]);
      }
    });
  }
  , methods: {
    infoProcess(nftInfo) {
      nftInfo.price = math.divide(nftInfo.price, 100);
      if (!nftInfo.artistAvatar || nftInfo.artistAvatar.length < 1) {
        nftInfo.artistAvatar = require('/src/assets/user_default_avatar.png');
      }
    }
    , getCarousels: async function (cb) {
      await request({
        url: '/index/slideshow',
        method: 'get',
        params: {}
      }).then(res => {
        console.log(res);
        cb(res.data);
      });
    }
    , getRecommends: async function (cb) {
      await request({
        url: '/index/recommend',
        method: 'get',
        params: {}
      }).then(res => {
        console.log(res);
        cb(res.data);
      });
    }
    , doFollow() {
      if (global.isLogin()) {
        this.loginModal = true;
      } else {
        this.loginModal = false;
      }
    }
    , clickRecommend(nftId) {
      location.href = global.viewUrl + '/product?nftId=' + nftId;
    }
  }
}
</script>

<style>
._singe ._item {
  margin-bottom: 4vw;
}

._singe ._item ._artworkUrl {
  width: 100%;
  text-align: center;
}

._singe ._item ._artworkUrl img {
  height: 31vw;
  border-radius: 1vw;
  box-shadow: 2px 2px 2px #888888;
  margin: 0 auto;
}

._singe ._item ._text {
  color: #fff;
}

._singe ._item ._text div {
  margin-top: 1vw;
}

._singe ._item ._text ._name {
  font-size: xx-large;
  height: 14vw;
}

._singe ._item ._text ._price {
  font-size: x-large;
}

._singe ._item ._text ._nftTotal span {
  font-size: 1.2vw;
  padding: 4px 5px;
}

._singe ._item ._text ._nftTotal span:nth-child(1) {
  background-color: #6b3dee;
}

._singe ._item ._text ._nftTotal span:nth-child(2) {
  background: linear-gradient(96.69deg, #5d32b899 34.42%, #451db799 103.44%);
}

._singe ._item ._user ._nickname {
  font-size: large;
  line-height: 2.5vw;
}

._singe ._item ._user img {
  width: 3.5vw;
  height: 3.5vw;
}


.artists ._artist {
  margin-bottom: 20px;
  background-color: #1b1a32;
  border-radius: 5px;
  border: 1px solid #5730bf;
  text-align: center;
  color: #fff;
  width: 90%;
  padding: 20px 10px 10px;
}

.artists ._artist ._avatar img {
  width: 8vw;
  height: 8vw;
  border-radius: 50%;
  margin-bottom: 10px;
}

.artists ._artist ._nickname {
  margin-bottom: 15px;
  font-weight: bolder;
  font-size: 16px;
}

.artists ._artist ._follow, .artists ._artist ._followed {
  background-color: #7358fe;
  padding: 2px 0;
  width: 40%;
  margin: 0 auto 15px;
  border-radius: 4%;
}

.artists ._artist ._followed {
  background-color: rgba(121, 148, 88, 0.89);
}

.artists ._artist ._fans {

}
</style>
