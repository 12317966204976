import { createApp } from 'vue'
import App from './App.vue'
import ArcoVue from '@arco-design/web-vue';
import '@arco-design/web-vue/dist/arco.css';
import $ from 'jquery'
import global from '/src/global';
import ArcoVueIcon from '@arco-design/web-vue/es/icon';
import { install } from 'vexip-ui'
import 'vexip-ui/css/index.css'

window.global = global;

const app = createApp(App)
app.use(ArcoVue);
app.use(ArcoVueIcon);
app.use($);
app.use(install);
app.mount('#app');

app.config.errorHandler = (err) => {
    console.log(err);
}

app.provide('global', global)