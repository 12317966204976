<template>
  <div class="modal">
    <Modal v-model:active="activeModal" width="500" no-footer="true" style="padding: 0" top="auto">
      <div class="mail">
        <div class="titleMarker"></div>
        <div class="body">
          <div class="starup"><img :src="require('/src/assets/airdrop_redeem_starup.png')"></div>
          <div class="stardown"><img :src="require('/src/assets/airdrop_redeem_stardown.png')"></div>
          <div class="text">
            <span>当前还未登录，请登录后进行操作</span>
          </div>
        </div>
        <div class="footer">
          <a-row>
            <a-col span="12">
              <div class="cancel" @click="cancel">跳过</div>
            </a-col>
            <a-col span="12">
              <div class="goLogin" @click="goLogin">去登录</div>
            </a-col>
          </a-row>
        </div>
        <div class="title">
          <a-row>
            <a-col span="6"></a-col>
            <a-col span="12">
              <div class="text"><span>登录提示</span></div>
            </a-col>
            <a-col span="2"></a-col>
            <a-col span="2">
              <div class="closeIcon" @click="cancel">
                <icon-close/>
              </div>
            </a-col>
          </a-row>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'LoginModal',
  props: {
    active : Boolean
  }
  , data() {
    return {
      activeModal: this.active == true
    }
  }
  , mounted() {
    $('.vxp-modal__content').css('padding', 0)
  }
  , methods: {
    cancel() {
      this.activeModal = false;
    }
    , goLogin() {
      location.href = '/login'
    }
  }
}
</script>
<style scoped>
.mail {
  background-color: #473cae !important;
  color: #fff;
  width: 100%;
}

.title {
  color: #fff;
  position: absolute;
  width: 100%;
  height: 60px;
  top: -30px;
  left: 80px;
  z-index: 1024;
  background: transparent;
  background-image: url("../../assets/payment_modal_header.png");
  background-repeat: no-repeat;
}

.title .text {
  margin: 10px 0;
  font-size: 18px;
}

.title .closeIcon {
  position: absolute;
  background-color: #7b7b7b;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  text-align: center;
  top: 10px;
  right: 66px;
  cursor: pointer;
}

.titleMarker {
  height: 50px;
}

.body {
  height: 80px;
}

.body .text {
  margin-top: 40px;
  text-align: center;
  font-size: 16px;
}

.body .starup, .body .stardown {
  position: absolute;
}

.body .starup {
  top: 40px;
  left: 20px;
}

.body .stardown {
  top: 100px;
  right: 40px;
}

.footer {
  padding-bottom: 20px;
}

.footer .cancel, .footer .goLogin {
  border: 1px solid #999;
  margin: 0 50px;
  text-align: center;
  padding: 5px 0;
  font-size: 16px;
  cursor: pointer;
}

.footer .goLogin {
  background: radial-gradient(111.36% 1709.09% at 100% 111.36%, #5f35d5 0, #6433f3 27.1%, #8951ff 100%)
}
</style>